import React, { Component, Fragment } from "react";
import ReactLoading from "react-loading";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment'
import { Doughnut } from "react-chartjs-2";
import { Button, Modal } from 'antd'

import creApi from "../../services/creService";
import ViolationBlock from "./partials/violationBlock";
import RecentActivity from "./partials/recentActivity";
import AddressHeaderBlock from "./partials/addressHeaderBlock";
import SnapshotDataMenu from '../dashboard/snapshotDataMenu'
import SmallCalendar from '../calendar/smallCalendar';
import Marker from './maps/Marker';
import GoogleMap from './maps/GoogleMap';
import FileAttachments from './fileAttachments'
import guard from '../../common/guard';
import QuestionDisplayInterface from "../questionnaire/questionsBlocks";
import Chart from 'chart.js';
import './violationsAllComponent.css'
import ModalVendors from '../../pages/violations/violationsTable/components/ModalVendors';
import ModalTenants from '../../pages/violations/violationsTable/components/ModalTenants';
const colors = [
    "#62cc93",
    "#eb6c23",
    "#62c3cc"
]
Chart.pluginService.register({
    beforeRender: function (chart) {
        if (chart.config.options.showAllTooltips) {
            // create an array of tooltips
            // we can't use the chart tooltip because there is only one tooltip per chart
            chart.pluginTooltips = [];
            chart.config.data.datasets.forEach(function (dataset, i) {
                chart.getDatasetMeta(i).data.forEach(function (sector, j) {
                    chart.pluginTooltips.push(new Chart.Tooltip({
                        _chart: chart.chart,
                        _chartInstance: chart,
                        _data: chart.data,
                        _options: chart.options.tooltips,
                        _active: [sector]
                    }, chart));
                });
            });

            // turn off normal tooltips
            chart.options.tooltips.enabled = false;
        }
    },
    afterDraw: function (chart, easing) {
        if (chart.config.options.showAllTooltips) {
            // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
            if (!chart.allTooltipsOnce) {
                if (easing !== 1)
                    return;
                chart.allTooltipsOnce = true;
            }

            // turn on tooltips
            chart.options.tooltips.enabled = true;
            Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
                tooltip.initialize();
                tooltip.update();
                // we don't actually need this since we are not animating tooltips
                tooltip.pivot();
                tooltip.transition(easing).draw();
            });
            chart.options.tooltips.enabled = false;
        }
    }
});

class ViolationsAll extends Component {
    state = this.props.jestData !== undefined ? this.props.jestData : {
        doneLoadingViolations: false,
        creDefaultViolations: {},
        addressData: {},
        violationsData: {},
        filter: {},
        places: [],
        hasStopWorkOrders: false,
        hasVacateOrders: false,
        selectedDate: null,
        snapshotData: {},
        snapshotDataLoading: false,
        questionBlocks: [],
        savedQuestionAnswers: [],
        showNumber: 3,
        questionnaireAnswersModalVisible: false,
        currentAnswer: null,
        vendors: ['n/a'],
        users: ['n/a'],
        appts: ['n/a'],
        modalVendorColumn: false,
        modalTenant: false,
    };


    setSelectedDate = (selectedDate) => this.setState({ selectedDate, snapshotData: {} });

    getViolationsData = async (bin) => {
        try {
            let propertyInfo = await creApi.getPropertyInfo(bin);
            if (propertyInfo.data?.status === false) {
                this.props.history.push('/my-buildings')
                toast.error(propertyInfo.data?.message)
            } else {
                this.setState({ addressData: propertyInfo.data });

                let violationsData = await creApi.getViolationsData(bin);

                const { ECB, DOB, HPD } = violationsData.data
                const dataCounts = [DOB, HPD, ECB].map(el => {
                    let count = 0
                    for (let key in el) {
                        if (el[key].Open) {
                            count += el[key].Open
                        }
                    }
                    return count
                })
                let customData = {}
                // check permission
                if (guard({
                    allow: () => true,
                    deny: () => false,
                    serviceName: 'custom-compliance',
                    action: 'compliance-full-access',
                })) {
                    let cutsom = await creApi.getCustomComplianceDashboard(bin);
                    if (cutsom.data?.customCompliances) {
                        customData['Compliace Portal'] = cutsom.data?.customCompliances.reduce((ac, a) => {
                            if (a.totalAllTime) {
                                ac[a.name] = a.totalAllTime
                            }
                            return ac

                        }, {})
                    }
                }



                this.setState({
                    dataCounts,
                    doneLoadingViolations: true,
                    creDefaultViolations: { ...violationsData.data, ...customData },
                });
                this.identityVendorNotifications(violationsData.data);
            }
        } catch (e) {
            toast.error(e.message)
        }
    }
    getViolationsDataSnapshot = async (bin) => {
        this.setState({
            snapshotDataLoading: true
        });
        try {
            if (this.state.selectedDate) {
                const date = moment(new Date(this.state.selectedDate)).format('L')
                let snapshotData = await creApi.getViolationsDataSnapshot(bin, date);
                this.setState({
                    snapshotData: snapshotData.data
                });
            } else {
                this.setState({
                    snapshotData: null
                });
            }
        } catch (e) {
            toast.error(e.message)
        }
        this.setState({
            snapshotDataLoading: false
        });
    }

    getQuestionary = async () => {
        try {

            let response = await creApi.getQuestionaryDataClient(this.props.match.params.id);
            this.setState({
                questionBlocks: response.data.questionBlocks,
                savedQuestionAnswers: response.data.savedAnswers
            });

        } catch (e) {
            toast.error(e.message)
        }

    }
    getVendors = async () => {
        try {

            const res = await creApi.getVendors();

            this.setState({
                vendors: res.data.vendors.map(el => el.fullName)
            });

        } catch (e) {
            toast.error(e.message)
        }

    }
    getSubUsers = async () => {
        try {

            const subUsersRes = await creApi.getSubUsers();

            this.setState({
                users: subUsersRes.data.data.map(el => el.fullName)
            });

        } catch (e) {
            toast.error(e.message)
        }

    }
    getBuildings = async () => {
        try {
            const buildingRes = await creApi.getBuildingsListOnly();


            this.setState({
                buildings: buildingRes.data
            });

        } catch (e) {
            toast.error(e.message)
        }

    }
    getApartments = async () => {
        try {
            const response = await creApi.getApartments(this.props.match.params.id);

            if (response.data.status) {
                this.setState({
                    appts: response.data.data
                });
            }


        } catch (e) {
            toast.error(e.message)
        }

    }


    componentDidMount() {
        //setInterval( () => {this.setState({doneLoadingViolations: true,doneLoadingActivity: true})}, 1000 )
        // this.getBuildings()
        this.getVendors()
        this.getSubUsers()
        this.getApartments()
        this.getQuestionary()
        this.getViolationsData(this.props.match.params.id);
    }

    setVPU = (VPU) => {
        this.setState({ addressData: { ...this.state.addressData, VPU } })
    }

    componentDidUpdate(prevProps, prevState) {
        // Typical usage (don't forget to compare props):
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.setState({ doneLoadingViolations: false })
            this.getViolationsData(this.props.match.params.id);
        }
        if (this.state.selectedDate !== prevState.selectedDate) {
            this.getViolationsDataSnapshot(this.props.match.params.id);
        }
    }

    identityVendorNotifications = (data) => {
        if (data.DOB?.Vacate_Order.Open > 0) {
            this.setState({ hasVacateOrders: true })
        }
        if (data.DOB?.Stop_Work_Order.Open > 0) {
            this.setState({ hasStopWorkOrders: true })
        }
    }
    questionSaveHandler = async (questions) => {
        try {

            await creApi.saveQuestionaryDataClient(this.props.match.params.id, questions);
            this.setState({
                questionnaireModalVisible: false
            })
            this.getQuestionary()

        } catch (e) {
            toast.error(e.message)
        }
    }

    handleBack = () => {
        this.props.history.push('/my-buildings')
    };

    render() {
        const SWO = this.state.addressData.SWO
        const VO = this.state.addressData.VO
        const lat = this.state.addressData.hasOwnProperty('coordinates') ? this.state.addressData.coordinates['lat'] : 40.8174;
        const lng = this.state.addressData.hasOwnProperty('coordinates') ? this.state.addressData.coordinates['lng'] : -73.9576;
        return (
            <Fragment>
                <ToastContainer />

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-xl-9 building-detail pr-2">
                            <div className="row ">
                                <div className="col-md-12">
                                    {this.state.addressData &&
                                        <AddressHeaderBlock data={this.state.addressData} handleGoBack={this.handleBack} setVPU={this.setVPU} />
                                    }
                                </div>
                            </div>
                            <div className="row-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {this.state.hasVacateOrders && <div className="alert alert-warning text-uppercase text-center font-weight-bold">
                                            {
                                                VO.includes('Y1') ? 'Full Vacate Order Exists on this property' : (
                                                    VO.includes('Y3') ? 'Partial Vacate Order Exists on this property' : 'Vacate Order Exists on this property'
                                                )
                                            }
                                        </div>}
                                        {this.state.hasStopWorkOrders && <div className="alert alert-danger text-uppercase text-center font-weight-bold">
                                            {
                                                SWO.includes('A3') ? 'Full Stop Work Order exists on this property' : (
                                                    SWO.includes('L1') ? 'Partial Stop Work Order exists on this property' : 'Stop Work Order exists on this property'
                                                )
                                            }

                                        </div>}
                                        <div className="map" style={{ height: '280px' }}>
                                            <GoogleMap
                                                defaultZoom={16}
                                                center={[parseFloat(lat), parseFloat(lng)]}
                                                lat={parseFloat(lat)}
                                                lng={parseFloat(lng)}
                                                yesIWantToUseGoogleMapApiInternals
                                                loaded={this.state.doneLoadingViolations}
                                            >
                                                <Marker
                                                    lat={lat}
                                                    lng={lng}
                                                    name="New York"
                                                    color="blue"
                                                />
                                            </GoogleMap>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <SnapshotDataMenu selectedDate={this.state.selectedDate} setSelectedDate={this.setSelectedDate} />
                                </div>
                                <div className="row">
                                    {!this.state.doneLoadingViolations ? (<div className="col-md-12"><ReactLoading type={"cylon"} color={"grey"} /> </div>) :
                                        (
                                            Object.keys(this.state.creDefaultViolations)
                                                .filter((violationType) => (Object.keys(this.state.creDefaultViolations[violationType] || {}).length !== 0) || (violationType === 'Compliace Portal'))
                                                .map((violationType, index) =>
                                                    <div className={`${violationType === 'Compliace Portal' ? 'flex-column' : ''} ${this.state.addressData?.locationEnv === 'Philadelphia' ? 'col-12' : 'col-xl-3 col-md-4'} d-flex`} key={index}>
                                                        <ViolationBlock
                                                            addressData={this.state.addressData}
                                                            title={violationType}
                                                            data={this.state.creDefaultViolations[violationType]}
                                                            snapshotData={this.state.snapshotData?.[violationType]}
                                                            snapshotDataLoading={this.state.snapshotDataLoading}
                                                            doneLoading={this.state.doneLoadingViolations}
                                                            bin={this.state.addressData.bin}
                                                            history={this.props.history}
                                                            snapshotDate={this.state.selectedDate}
                                                        />
                                                        {guard({
                                                            allow: () => true,
                                                            deny: () => false,
                                                            serviceName: 'questionnaire',
                                                        }) && violationType === 'Compliace Portal' &&
                                                            <div className="card flex-fill">
                                                                <h5>Questionnaire</h5>
                                                                <div className="log-content">
                                                                    <div className="logs-element d-flex justify-content-between">
                                                                        <Button type="dashed" block onClick={() => this.setState({ questionnaireModalVisible: true })}>
                                                                            Add New
                                                                        </Button>
                                                                    </div>
                                                                    {this.state.savedQuestionAnswers?.slice(0, this.state.showNumber).map(el => {
                                                                        const appNumber = el.questions.find(el => el.type === 'client appt')?.answer
                                                                     
                                                                        return (<div
                                                                            key={el._id}
                                                                            className="logs-element d-flex justify-content-between pointer questionAnswer"
                                                                            onClick={() => this.setState({
                                                                                currentAnswer: el,
                                                                                questionnaireAnswersModalVisible: true
                                                                            })}
                                                                        >
                                                                            <span>{moment(new Date(el.addedAt)).format('L')}</span>
                                                                            <span className='d-flex align-items-center text-center mr-2'>
                                                                                {el.blockName}{appNumber ? ` (${appNumber})` : ''}
                                                                            </span>
                                                                        </div>)
                                                                    }
                                                                    )}
                                                                    {this.state.showNumber < this.state.savedQuestionAnswers?.length &&
                                                                        <div className="d-flex justify-content-center">
                                                                            <Button type="dashed" onClick={() => this.setState({ showNumber: this.state.showNumber + 3 })}>show more</Button>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </div>}
                                                    </div>
                                                ))
                                    }



                                </div>
                            </div>
                        </div>

                        <div className="col-xl-3 recent-activity pl-0 pr-0">
                            <div className="pt-3 pb-3">
                                {this.props.jestData !== undefined ? null : <SmallCalendar />}
                            </div>
                            {this.state.dataCounts && this.state.addressData?.locationEnv === 'NYC' &&
                                <div className="card user-card pointer">

                                    <Doughnut
                                        data={{
                                            labels: ['DOB', 'HPD', 'ECB'],
                                            datasets: [{
                                                data: this.state.dataCounts,
                                                backgroundColor: colors,
                                                hoverBackgroundColor: colors
                                            }]
                                        }}
                                        options={{
                                            responsive: true,
                                            maintainAspectRatio: true,
                                            showAllTooltips: true,
                                            tooltips: {
                                                yAlign: 'bottom',
                                                xAlign: 'right',
                                                rtl: true,
                                                backgroundColor: 'rgba(0, 0, 0, 0.4)',
                                                callbacks: {
                                                    label: (tooltipItem, data) => {
                                                        var dataset = data.datasets[tooltipItem.datasetIndex];
                                                        var total = dataset.data.reduce((previousValue, currentValue) => {
                                                            return previousValue + currentValue;
                                                        });
                                                        var currentValue = dataset.data[tooltipItem.index];
                                                        var precentage = Math.floor(((currentValue / total) * 100) + 0.5);
                                                        return precentage + "%";
                                                    }
                                                },


                                            },
                                            onClick: (evt, element) => {
                                                if (element.length > 0 && element[0]?._view?.label) {
                                                    this.props.history.push(`/violations/category/${this.state.addressData.bin}/${element[0]._view.label}/Open`)
                                                }
                                            }
                                        }}
                                    />
                                </div>
                            }
                            <FileAttachments bin={this.state.addressData.bin} />
                            <RecentActivity
                                type="buildingActivity"
                                match={this.props.match}
                                history={this.props.history}
                                bin={this.props.match.params.id}
                            />
                        </div>

                    </div>
                </div>
                <Modal
                    style={{ top: 20 }}
                    title='Questionnaire'
                    visible={this.state.questionnaireModalVisible}
                    footer={null}
                    onCancel={() => this.setState({ questionnaireModalVisible: false })}
                    width={'1000px'}
                >

                    <QuestionDisplayInterface
                        questionBlocks={this.state.questionBlocks}
                        questionSaveHandler={this.questionSaveHandler}
                        savedQuestionAnswers={this.state.savedQuestionAnswers}
                        bin={this.props.match.params.id}
                        vendors={this.state.vendors}
                        users={this.state.users}
                        // buildings={this.state.buildings}
                        setModalVendorColumn={() => { this.setState({ modalVendorColumn: true }) }}
                        setModalTenant={() => { this.setState({ modalTenant: true }) }}
                        address={this.state.addressData}
                        appts={this.state.appts}
                    />
                </Modal>
                <Modal
                    style={{ top: 20 }}
                    title='Questionnaire Answers'
                    visible={this.state.questionnaireAnswersModalVisible}
                    footer={null}
                    onCancel={() => this.setState({ questionnaireAnswersModalVisible: false })}
                    width={'1000px'}
                >
                    <h3 className='question-block'> {this.state.currentAnswer?.blockName}</h3>
                    <div className='d-flex question-answer mt-2' >
                        <div className='w-50 text-bold'>Question</div>
                        <div className='w-50 text-bold'>Response</div>
                    </div>
                    {
                        this.state.currentAnswer?.questions.map(el => {

                            return (
                                <div key={el.question + el.answer} className='d-flex question-answer mt-2' >
                                    <div className='w-50'>{el.question}</div>
                                    <div className='w-50'>{
                                       el.answer && el.type === 'client upload' && typeof el.answer !== 'string' ?

                                            el.answer.map(key => (

                                                <a className='mr-2' key={key} href={`${window.location.origin}/api/documents/get-by-key?key=${key}`} target='_blank' rel="noreferrer">
                                                    {key.split('-').reverse()[0]}
                                                </a>
                                            )) : el.answer || '-'}
                                    </div>
                                </div>
                            )
                        })}
                </Modal>
                <ModalVendors
                    modalVendorColumn={this.state.modalVendorColumn}
                    handleAddCreatedVendor={() => { this.getVendors() }}
                    setModalVendorColumn={(val) => this.setState({ modalVendorColumn: val })}
                />
                <ModalTenants
                    modalTenant={this.state.modalTenant}
                    handleAddCreatedTenant={() => { this.getApartments() }}
                    setModalTenant={(val) => this.setState({ modalTenant: val })}
                    bin={this.props.match.params.id}
                />
            </Fragment >

        );
    }
}

export default ViolationsAll;
